import React,{useState,useContext,useEffect} from 'react'
import axios from 'axios';
import { Pagination } from "antd"; 
import { UserIdContext } from '../context/walletContext';
import { ColorRing } from 'react-loader-spinner';
import { base_url } from './Base_url'
import { useParams } from 'react-router-dom';
const PoolChartC1 = () => {
    const { pool_id } = useParams();

    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [totalPage, settotalPage] = useState(1)
    const [page, setPage] = useState(1);
        const [skip,setSkip] = useState(0);
    const limit = 10;
    const [error, setError] = useState(null);
    const {userId}=useContext(UserIdContext)
    const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //     fetchData();
    // }, [pool_id]);
    useEffect(() => {
        const fetchDataAsync = async () => {
            setIsLoading(true);
            try {
                await fetchData();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error("Error fetching data:", error);
                setError(error);
            }
        };

        fetchDataAsync();
    }, [pool_id]);

    const handlePaginationChange = (page) => {
        const newSkip = (page - 1) * limit;
        setPage(page);
        setSkip(newSkip);
        fetchData(newSkip); // Call fetchData with the newSkip value
    };


    const fetchData = (skip=0) => {
        setIsLoading(true);
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url:`${base_url}/Api/pool_chart/${userId}/${pool_id}/`+skip,
            headers: {
                'Content-Type': "application/x-www-form-urlencoded"
            },
            // data: data
        };

        return   axios.request(config)
            .then((response) => {
                console.log(response);
                (JSON.stringify(response))
                console.log("Response:", response.data.members);
                 setTotal(response.data)
                setData(response.data.members);
                settotalPage(Math.ceil(response.data.records_count/limit))

            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setError(error);
            }).finally(() => {
                setIsLoading(false);
            });
    }

  return (
    <>
    <section className='sec01_pool1'>
        <div className='container'>
        <h2 className='sec_heading_h2 w josefin text-center'>Pool Chart C{pool_id}</h2>
            <div className='sec2_box'>
            <div className='over'>
                {isLoading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
                                    <ColorRing colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']} height={65} width={65} />
                                </div>
                            ) : (
                <table className='pool1_table'>
                    <tr className='table_row'>
                        <th className='table_heading josefin w'> S No.</th>
                        <th className='table_heading josefin w'> USER ID	</th>
                        <th className='table_heading josefin w'> UPLINE ID	</th>
                        <th className='table_heading josefin w'> TEAM	</th>
                        <th className='table_heading josefin w'> ENTER DATE</th>
                    </tr>
                    { data?.length > 0 ? (
                    data?.map((item, index) =>{
                        const serialNumber = (page - 1) * limit + index + 1;
                        return (
                    <tr key={index}className='table_row'>
                        <td className='table_data inter w'>{serialNumber}</td>
                        <td className='table_data inter w'>{item.user_id}</td>
                        <td className='table_data inter w'>{item.upline_id} </td>
                        <td className='table_data inter w'>{item.down_count}</td>
                        <td className='table_data inter w'>{item.created_at}</td>
                    </tr>
                     )})):(
                        <tr >
                          <td className="table_data inter w">Data not found</td>
                        </tr>
                     )}
                </table>
                            )}
                            <div className='flex2'>
                                

                                <Pagination
                                                                    style={{ cursor: "pointer" }}
                                                                    className="mx-auto"
                                                                    current={page}
                                                                    total={totalPage * limit} // Ensure this reflects the total count of items
                                                                    pageSize={limit}
                                                                    showLessItems
                                                                    showMoreItems
                                                                    onChange={handlePaginationChange}
                                                                    showSizeChanger={false}
                                                                    showQuickJumper={false}
                                                                />
                                                            </div>
                            </div > 
            </div>
            
        </div>
    </section>
    </>
  )
}

export default PoolChartC1