import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Pagination } from "antd";
import { UserIdContext } from '../context/walletContext';
import { ColorRing } from 'react-loader-spinner';
import { base_url } from './Base_url'
const DirectTeam = () => {
    const [direct, setDirect] = useState([]);
    const [error, setError] = useState(null);
    const [totalPage, setTotalPage] = useState(1)
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const limit = 10;
    const { userId } = useContext(UserIdContext)

    const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //     fetchData();
    // }, []);

    useEffect(() => {
        const fetchDataAsync = async () => {
            setIsLoading(true);
            try {
                await fetchData();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error("Error fetching data:", error);
                setError(error);
            }
        };

        fetchDataAsync();
    }, []);


    const handlePaginationChange = (page) => {
        const newSkip = (page - 1) * limit;
        setPage(page);
        setSkip(newSkip);
        fetchData(newSkip); // Call fetchData with the newSkip value
    };



    const fetchData = (skip = 0) => {
        setIsLoading(true);
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${base_url}/Api/directs/${userId}/` + skip,
            headers: {
                'Content-Type': "application/x-www-form-urlencoded"
            },
            // data: data
        };

        return axios.request(config)
            .then((response) => {
                console.log(response);
                (JSON.stringify(response))
                console.log("Response:", response.data);
                setDirect(response.data?.directs);
                const pageChange = (e, data) => {
                    let page = data.activePage;
                    let pageNo = page === 1 ? 0 : ((page - 1) * limit);
                    setSkip(pageNo)
                    fetchData(pageNo)
                }
                setTotalPage(Math.ceil(response.data?.records_count / limit));

            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setError(error);
            }).finally(() => {
                setIsLoading(false);
            });
    }




    // const totalPages = Math.ceil(direct.length / perPage);



    return (
        <>
            <section className='sec01_pool1'>
                <div className='container'>
                    <h2 className='sec_heading_h2 w josefin text-center'>Direct Team</h2>
                    <div className='sec2_box'>
                        <div className='d-flex table_pagi'>
                           
                           
                        </div>
                        <div className='over'>
                        {isLoading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
                                    <ColorRing colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']} height={65} width={65} />
                                </div>
                            ) : (
                        
                        <table className='pool1_table'>
                            
                            <tr className='table_row'>
                                <th className='table_heading josefin w'> S No.</th>
                                {/* <th className='table_heading josefin w'>Id</th> */}
                                <th className='table_heading josefin w'>User Id</th>
                                <th className='table_heading josefin w'>Eth Address</th>
                                <th className='table_heading josefin w'>Package Amount</th>
                                {/* <th className='table_heading josefin w'>VIEW</th> */}

                            </tr>



                            {direct?.length > 0 ? (
                            direct?.map((direct, index) => {
                                const serialNumber = (page - 1) * limit + index + 1;
                                return (

                                    <tr key={index} className='table_row'>
                                        <td className='table_data inter w'>{serialNumber}</td>
                                        {/* <td className='table_data inter w'>{direct.id}</td> */}
                                        <td className='table_data inter w'>{direct.user_id}</td>
                                        <td className='table_data inter w'>{direct.eth_address}</td>
                                        <td className='table_data inter w'>{direct.package_amount}</td>
                                        {/* <td className='table_data inter w'><a href='/' className='table_link'>View</a></td> */}
                                    </tr>
                                )
                            })):(
                                <tr>
                          <td className="table_data inter w">Data not found</td>
                        </tr>
                            )}



                        </table>
                            )}
                            
                    </div>
                    <div className='flex2'>
                           
                           <Pagination
                               style={{ cursor: "pointer" }}
                               className="mx-auto"
                               current={page}
                               total={totalPage * limit} // Ensure this reflects the total count of items
                               pageSize={limit}
                               showLessItems
                               showMoreItems
                               onChange={handlePaginationChange}
                               showSizeChanger={false}
                               showQuickJumper={false}
                           />





                       </div>
                    </div>
                </div>
                
            </section>
        </>
    )
}

export default DirectTeam
