import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Pagination } from "antd";  // Import Ant Design Pagination

import { UserIdContext } from '../context/walletContext';
import { ColorRing } from 'react-loader-spinner';
import { base_url } from './Base_url'
const LevelIncome = () => {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [totalPage, settotalPage] = useState(1)
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const limit = 10;
    const [error, setError] = useState(null);
    const { userId } = useContext(UserIdContext)
    const [isPage, setIsPage] = useState(0)
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchDataAsync = async () => {
            setIsLoading(true);
            try {
                await fetchData();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error("Error fetching data:", error);
                setError(error);
            }
        };

        fetchDataAsync();
    }, []);

    const handlePaginationChange = (page) => {
        const newSkip = (page - 1) * limit;
        setPage(page);
        setSkip(newSkip);
        fetchData(newSkip); // Call fetchData with the newSkip value
    };
    


    const fetchData = (skip = 0) => {
        setIsLoading(true);
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${base_url}Api/income/${userId}/level_income/` + skip,
            headers: {
                'Content-Type': "application/x-www-form-urlencoded"
            },
            // data: data
        };

        return axios.request(config)
            .then((response) => {
                console.log(response);
                (JSON.stringify(response))
                console.log("Response:", response.data.income_list);
                // setOther(response.data)
                settotalPage(Math.ceil(response.data.records_count / limit))

                setTotal(response.data)
                setData(response.data.income_list);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setError(error);
            }).finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <>
            <section className='sec01_pool1'>
                <div className='container'>
                    <h2 className='sec_heading_h2 w josefin text-center'>Level Income</h2>
                    <div className='sec2_box'>
                        <div className='d-flex table_pagi'>
                            <div className='flex1'>
                                <h3 className='table_sub_title josefin w' >( Total : ${total.total_sum} )</h3>
                            </div>
                            {/* <div className='flex2'>


                            <Pagination
                                    style={{ cursor: "pointer" }}
                                    className="mx-auto"
                                    current={page}
                                    total={totalPage * limit} // Ensure this reflects the total count of items
                                    pageSize={limit}
                                    showLessItems
                                    showMoreItems
                                    onChange={handlePaginationChange}
                                    showSizeChanger={false}
                                    showQuickJumper={false}
                                />
                            </div> */}
                        </div>
                        <div className='over'>
                        {isLoading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
                                    <ColorRing colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']} height={65} width={65} />
                                </div>
                            ) : (
                            <table className='MyTeamDirects_table'>
                                <tr className='table_row'>
                                    <th className='table_heading josefin w'> S No.</th>
                                    <th className='table_heading josefin w'>AMOUNT	</th>
                                    <th className='table_heading josefin w'>TYPE</th>
                                    <th className='table_heading josefin w'>DESCRIPTION	</th>
                                    <th className='table_heading josefin w'>DATE</th>
                                </tr>
                                {data?.length > 0 ? (
                                data?.map((item, index) => {
                                    const serialNumber = (page - 1) * limit + index + 1;
                                    return (
                                        <tr key={index} className='table_row'>
                                            <td className='table_data inter w'>{serialNumber}</td>
                                            <td className='table_data inter w'>{item.amount}</td>
                                            <td className='table_data inter w'>{item.type}</td>
                                            <td className='table_data inter w'>{item.description}	</td>
                                            <td className='table_data inter w'>{item.created_at}</td>
                                        </tr>
                                    )
                                })):(
                                    <tr>
                                    <td className="table_data inter w">Data not found</td>
                                  </tr>
                                )}
                            </table>
                            )}
                        </div>
                        <div className='flex2'>


<Pagination
        style={{ cursor: "pointer" }}
        className="mx-auto"
        current={page}
        total={totalPage * limit} // Ensure this reflects the total count of items
        pageSize={limit}
        showLessItems
        showMoreItems
        onChange={handlePaginationChange}
        showSizeChanger={false}
        showQuickJumper={false}
    />
</div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LevelIncome