import React,{useState,useContext,useEffect} from 'react'
import axios from 'axios';
import { Pagination } from "antd";
import { UserIdContext } from '../context/walletContext';
import { ColorRing } from 'react-loader-spinner';
import { base_url } from './Base_url'
const WithdrawList = () => {
    const [data, setData] = useState([]);
    const [other, setOther] = useState('');
    const [totalPage, settotalPage] = useState(1)
    const [page, setPage] = useState(1);
    const [skip,setSkip] = useState(0);
    const limit = 10;
    const [error, setError] = useState(null);
    const {userId}=useContext(UserIdContext)
    const [isLoading, setIsLoading] = useState(true);
    console.log("user..........",userId);

    useEffect(() => {
        const fetchDataAsync = async () => {
            setIsLoading(true);
            try {
                await fetchData();
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error("Error fetching data:", error);
                setError(error);
            }
        };

        fetchDataAsync();
    }, []);


    const handlePaginationChange = (page) => {
        const newSkip = (page - 1) * limit;
        setPage(page);
        setSkip(newSkip);
        fetchData(newSkip); // Call fetchData with the newSkip value
    };
    const fetchData = (skip = 0) => {
        setIsLoading(true);
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url:`${base_url}/Api/withdraw_history/${userId}/`+skip,
            headers: {
                'Content-Type': "application/x-www-form-urlencoded"
            },
            // data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(response);
                (JSON.stringify(response))
                console.log("Response:", response.data.transactions);
                setOther(response.data)
                settotalPage(Math.ceil(response.data.records_count/limit))
                // Math.ceil(direct.length / perPage)
                setData(response.data.transactions);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setError(error);
            }) .finally(() => {
                setIsLoading(false);
            });
    }
  return (
    <>
     <section className='sec01_pool1'>
        <div className='container'>
        
        <h2 className='sec_heading_h2 w josefin text-center'>Withdraw Request / Withdraw summary</h2>  
            <div className='sec2_box'>
                <div className='d-flex table_pagi'>
                    <div className='flex1'>
                        <h3 className='table_sub_title josefin w' >(Total : `${other.total_sum}`|Payable : `${other.payableAmount}`)</h3>
                    </div>
                    {/* <div className='flex2'>
                    <Pagination
                                    style={{ cursor: "pointer" }}
                                    className="mx-auto"
                                    current={page}
                                    total={totalPage * limit} // Ensure this reflects the total count of items
                                    pageSize={limit}
                                    showLessItems
                                    showMoreItems
                                    onChange={handlePaginationChange}
                                    showSizeChanger={false}
                                    showQuickJumper={false}
                                />
                    </div> */}
                </div>
                <div className='over'>
                {isLoading ? (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
                                    <ColorRing colors={['#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1', '#08d1d1']} height={65} width={65} />
                                </div>
                            ) : (
                <table className='withdraw_table'>
                    <tr className='table_row'>
                        <th className='table_heading josefin w'> S No.</th>
                        <th className='table_heading josefin w'>User ID	</th>
                        <th className='table_heading josefin w'>Amount</th>
                        <th className='table_heading josefin w'>Type	</th>
                        <th className='table_heading josefin w'>Status</th>
                        <th className='table_heading josefin w'>Deductions</th>
                        <th className='table_heading josefin w'>Payable Amount</th>
                        {/* <th className='table_heading josefin w'>Description	</th> */}
                        <th className='table_heading josefin w'>Credit Date</th>
                    </tr>
                    {data?.map((item, index) =>{
                        const serialNumber = (page - 1) * limit + index + 1;
                        return (
                    <tr key={index} className='table_row'>
                        <td className='table_data inter w'>{serialNumber}</td>
                        <td className='table_data inter w'>{item.user_id}</td>
                        <td className='table_data inter w'>{item.amount}</td>
                        <td className='table_data inter w'>{item.type}	</td>
                        <td className='table_data inter w'>{item.status}</td>
                        <td className='table_data inter w'>{item.deductions}</td>
                        <td className='table_data inter w'>{item.payable_amount}	</td>
                        {/* <td className='table_data inter w'>0xf68a770b66c9aff8a7db7461875f1f88826245b3c0a0943f37bfb487dd2b887f	</td> */}
                        <td className='table_data inter w'>{item.created_at}</td>
                    </tr>
                    )})}
                </table>
                            )}
                </div>
                <div className='flex2'>
                    <Pagination
                                    style={{ cursor: "pointer" }}
                                    className="mx-auto"
                                    current={page}
                                    total={totalPage * limit} // Ensure this reflects the total count of items
                                    pageSize={limit}
                                    showLessItems
                                    showMoreItems
                                    onChange={handlePaginationChange}
                                    showSizeChanger={false}
                                    showQuickJumper={false}
                                />
                    </div>
            </div>
        </div>
        </section>
    </>
  )
}

export default WithdrawList